import { useState, useEffect, useCallback } from "react";
import OptionDropdown from "./OptionDropdown";
import { useSelector, useDispatch } from "react-redux";
import useAuth from "../hooks/use-auth";
import classes from "./PlayerboardSelector.module.css";

import {
    fetchTeamPlayerboards,
    fetchDraftScoutPlayerboards,
} from "../store/playerboards-actions";

const boardOption = ({ optionValue }) => (
    <div className={classes.board_option}>
        <span>{optionValue.label}</span>
    </div>
);

export const RosterEditorVersionSelector = ({
    teamId,
    selectedVersionId,
    onVersionIdSelect,
}) => {
    const dispatch = useDispatch();
    const [versions, setVersions] = useState([]);
    const { user } = useAuth();

    const loadVersions = useCallback(async () => {
        try {
            const fetchedVersions = await dispatch(
                fetchTeamPlayerboards(teamId)
            );
            const allVersions = fetchedVersions
                .sort((a, b) => {
                    if (a.owner.last_name < b.owner.last_name) {
                        return -1;
                    }
                    if (a.owner.last_name > b.owner.last_name) {
                        return 1;
                    }
                    if (a.owner.first_name < b.owner.first_name) {
                        return -1;
                    }
                    if (a.owner.first_name > b.owner.first_name) {
                        return 1;
                    }
                    return 0;
                })
                .map((v) => ({
                    id: v.id,
                    owner: v.owner,
                    label: `${v.owner.first_name} ${v.owner.last_name}`,
                }));
            setVersions(allVersions);
            if (allVersions.length > 0) {
                // By default, select the version of the current user, otherwise select the first version
                const selectedVersion = allVersions.find((v) =>
                    selectedVersionId
                        ? v.id === selectedVersionId
                        : v.owner.email === user.email
                );
                onVersionIdSelect(selectedVersion?.id);
            }
        } catch (error) {
            console.error("Error fetching versions:", error);
        }
    }, [teamId, dispatch, user.email, onVersionIdSelect, selectedVersionId]);

    useEffect(() => {
        if (selectedVersionId) {
            const version = versions.find((v) => v.id === selectedVersionId);
            if (!version) {
                loadVersions();
            }
        }
    }, [selectedVersionId, versions, loadVersions]);

    useEffect(() => {
        if (teamId) {
            loadVersions();
        }
    }, [teamId, loadVersions]);

    const handleVersionIdSelect = (versionId) => {
        onVersionIdSelect(versionId);
    };

    return (
        <div className={classes.version_selector}>
            <PlayerboardSelector
                boards={versions}
                selectedBoardId={selectedVersionId}
                onBoardIdSelect={handleVersionIdSelect}
            />
        </div>
    );
};

export const DraftBoardSelector = ({
    draftId,
    selectedBoardId,
    onBoardIdSelect,
    includeMain = true,
}) => {
    const dispatch = useDispatch();
    const [boards, setBoards] = useState([]);
    const [isSingleOwner, setIsSingleOwner] = useState(true);
    const { user } = useAuth();
    const drafts = useSelector((state) => state.playerboards.drafts);
    const mainPlayerboardId = drafts[draftId]?.playerboard_id;

    const loadBoards = useCallback(async () => {
        try {
            const fetchedBoards = await dispatch(
                fetchDraftScoutPlayerboards(draftId)
            );
            // Determine if there is only one owner of the boards
            const singleOwnerFlag =
                new Set(fetchedBoards.map((b) => b.owner.email)).size < 2;
            setIsSingleOwner(singleOwnerFlag);

            const allBoards = fetchedBoards
                .sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })
                .map((b) => ({
                    id: b.id,
                    owner: b.owner,
                    label:
                        b.name +
                        (!singleOwnerFlag
                            ? ` (${b.owner.first_name} ${b.owner.last_name})`
                            : ""),
                }));
            if (includeMain) {
                allBoards.unshift({
                    id: mainPlayerboardId,
                    owner: null,
                    label: "Main",
                });
            }
            setBoards(allBoards);
            if (allBoards.length > 0) {
                // By default, select the board of the current user, otherwise select the first board
                const selectedBoard = allBoards.find((b) =>
                    selectedBoardId
                        ? b.id === selectedBoardId
                        : b.owner.email === user.email
                );
                onBoardIdSelect(selectedBoard?.id);
            }
        } catch (error) {
            console.error("Error fetching boards:", error);
        }
    }, [
        draftId,
        dispatch,
        user.email,
        onBoardIdSelect,
        selectedBoardId,
        includeMain,
        mainPlayerboardId,
    ]);

    useEffect(() => {
        if (selectedBoardId) {
            const board = boards.find((b) => b.id === selectedBoardId);
            if (!board) {
                loadBoards();
            }
        }
    }, [selectedBoardId, boards, loadBoards]);

    useEffect(() => {
        if (draftId) {
            loadBoards();
        }
    }, [draftId, loadBoards]);

    const handleBoardIdSelect = (boardId) => {
        onBoardIdSelect(boardId);
    };

    return (
        boards.length > 1 && (
            <div
                className={
                    isSingleOwner
                        ? classes.draftboard_selector
                        : classes.wide_draftboard_selector
                }
            >
                <PlayerboardSelector
                    boards={boards}
                    selectedBoardId={selectedBoardId}
                    onBoardIdSelect={handleBoardIdSelect}
                />
            </div>
        )
    );
};

const PlayerboardSelector = ({ boards, selectedBoardId, onBoardIdSelect }) => {
    const [selectedBoard, setSelectedBoard] = useState(null);

    useEffect(() => {
        if (selectedBoardId) {
            const board = boards.find((b) => b.id === selectedBoardId);
            if (board) {
                setSelectedBoard(board);
            }
        }
    }, [selectedBoardId, boards]);

    const handleBoardSelect = (board) => {
        setSelectedBoard(board);
        onBoardIdSelect(board.id);
    };

    return (
        <div className={classes.playerboard_selector}>
            <OptionDropdown
                options={boards}
                selectedOption={selectedBoard}
                onSelect={handleBoardSelect}
                OptionComponent={boardOption}
            />
        </div>
    );
};

export default PlayerboardSelector;
