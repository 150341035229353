import { useState, useEffect, useMemo, useCallback } from "react";
import { player_layout_positions } from "../../constants";
import { DragDropContext } from "react-beautiful-dnd";
import classes from "./PlayerGrid.module.css";
import { PlayerTileWidthLayouts } from "../playertile/Playertile";
import { PlayerTileModes } from "../../store/ui-slice";
import useElementDimensions from "../../hooks/use-element-dimensions";
import PlayerGridRow from "./PlayerGridRow";

const PlayerGrid = ({
    refId,
    grid_content,
    updatesAllowed = false,
    onTileMoved,
    onTileClicked,
    onInsertRow,
    onRemoveRow,
    tileView = PlayerTileModes.PERFORMANCE,
    tightLayout = false,
    stackView = false,
    showProjectedFreeAgencyStatus = false,
    showDraftTeamOverlay = false,
    showOwningTeamOverlay = false,
    alternateColumnLabels = null,
    rowCountersGroupSize = null,
}) => {
    const widthRangesConfig = useMemo(() => {
        const baseRanges = [
            { min: 1310, max: Infinity },
            { min: 1255, max: 1310 },
            { min: 1050, max: 1255 },
            { min: 770, max: 877 },
            { min: 700, max: 770 },
            { min: 600, max: 700 },
            { min: 520, max: 600 },
            { min: 400, max: 520 },
            { min: 0, max: 400 },
        ];

        return rowCountersGroupSize === null
            ? baseRanges
            : baseRanges.map(({ min, max }) => ({
                  min: min + 30,
                  max: max === Infinity ? max : max + 30,
              }));
    }, [rowCountersGroupSize]);

    const { elementRef, widthClasses } =
        useElementDimensions(widthRangesConfig);

    const findWidthClass = useCallback(
        () =>
            widthClasses[0]?.matches
                ? PlayerTileWidthLayouts.ULTRA_WIDE
                : widthClasses[1]?.matches
                ? PlayerTileWidthLayouts.ULTRA_WIDE_STRONG_OVERLAY
                : widthClasses[2]?.matches
                ? PlayerTileWidthLayouts.WIDE
                : widthClasses[3]?.matches
                ? PlayerTileWidthLayouts.NARROW
                : widthClasses[4]?.matches
                ? PlayerTileWidthLayouts.EXTRA_NARROW
                : widthClasses[5]?.matches
                ? PlayerTileWidthLayouts.ULTRA_NARROW
                : widthClasses[6]?.matches
                ? PlayerTileWidthLayouts.COMPACT
                : widthClasses[7]?.matches
                ? PlayerTileWidthLayouts.EXTRA_COMPACT
                : widthClasses[8]?.matches
                ? PlayerTileWidthLayouts.ULTRA_COMPACT
                : PlayerTileWidthLayouts.STANDARD,
        [widthClasses]
    );

    const [tileWidthLayout, setTileWidthLayout] = useState(() => {
        return findWidthClass();
    });

    useEffect(() => {
        const newLayout = findWidthClass();
        if (newLayout !== tileWidthLayout) {
            setTileWidthLayout(newLayout);
        }
    }, [findWidthClass, tileWidthLayout]);

    const handleDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (
            !destination ||
            (destination.droppableId === source.droppableId &&
                destination.index === source.index)
        ) {
            return;
        }

        // only keep the second and 3rd part of the id using slice
        const [src_row, src_position] = source.droppableId.split("-").slice(1);
        const [dest_row, dest_position] = destination.droppableId
            .split("-")
            .slice(1);
        const drag_player_id = draggableId.split("-")[3];

        onTileMoved(
            drag_player_id,
            parseInt(dest_row),
            dest_position,
            parseInt(src_row),
            src_position
        );
    };

    const row_count_labels = useMemo(() => {
        if (rowCountersGroupSize) {
            let prevCount = 0;
            let currentCount = 0;

            return grid_content.reduce((acc, row, idx) => {
                prevCount += currentCount;
                currentCount = Object.values(row).filter((v) => v).length;
                if (
                    Math.floor(prevCount / rowCountersGroupSize) !==
                        Math.floor(
                            (prevCount + currentCount) / rowCountersGroupSize
                        ) ||
                    idx === grid_content.length - 1
                ) {
                    acc[idx] = prevCount + currentCount;
                }
                return acc;
            }, {});
        }
        return null;
    }, [grid_content, rowCountersGroupSize]);

    return (
        <div
            ref={elementRef}
            className={`${classes.playergrid} ${
                tightLayout ? classes.tight_layout : ""
            }`}
        >
            <div
                className={`${classes.playergrid_row} ${classes.playergrid_header}`}
            >
                {player_layout_positions.map((position, idx) => (
                    <div className={classes.playergrid_cell} key={position}>
                        {(!stackView ||
                            grid_content.some((row) => row[position])) && (
                            <div className={classes.position_label}>
                                {alternateColumnLabels
                                    ? alternateColumnLabels[idx]
                                    : position}
                            </div>
                        )}
                    </div>
                ))}
                <div
                    className={`${classes.row_count_container} ${
                        rowCountersGroupSize ? "" : classes.no_count
                    }`}
                >
                    <div className={classes.row_count}></div>
                </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                {grid_content.map((row, rowIndex) => (
                    <PlayerGridRow
                        refId={refId}
                        key={rowIndex}
                        rowIndex={rowIndex}
                        row={row}
                        player_layout_positions={player_layout_positions}
                        updatesAllowed={updatesAllowed}
                        onInsertRow={onInsertRow}
                        onRemoveRow={onRemoveRow}
                        rowCountersGroupSize={rowCountersGroupSize}
                        row_count_labels={row_count_labels}
                        tileView={tileView}
                        onTileClicked={onTileClicked}
                        showDraftTeamOverlay={showDraftTeamOverlay}
                        showOwningTeamOverlay={showOwningTeamOverlay}
                        showProjectedFreeAgencyStatus={
                            showProjectedFreeAgencyStatus
                        }
                        tileWidthLayout={tileWidthLayout}
                        stackView={stackView}
                        onTileMoved={onTileMoved}
                    />
                ))}
            </DragDropContext>
        </div>
    );
};

export default PlayerGrid;
